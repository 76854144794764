<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>{{ tableName }}</b-card-title>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataItem"
      striped
      fixed
      small
    >
      <template #head(selected)>
        <b-form-checkbox
          v-model="allCheck"
          name="check-button"
          inline
          value="1"
          @change="interviewCloseCheckControl"
        />
      </template>
      <template #cell(selected)="data">
        <template v-if="interviewCloseList.length > 0">
          <b-form-checkbox
            v-model="interviewCloseList[data.index].selected"
            name="check-button"
            inline
            value="1"
            @change="interviewCheck(interviewCloseList[data.index].selected)"
          />
        </template>
        <template v-if="interviewTransferList.length > 0">
          <b-form-checkbox
            v-model="interviewTransferList[data.index].selected"
            name="check-button"
            inline
            value="1"
            @change="interviewCheck(interviewTransferList[data.index].selected)"
          />
        </template>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BCard, BCardTitle, BCardHeader, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'DuplicatedResultList',
  components: {
    BFormCheckbox,
    BTable,
    BCardTitle,
    BCard,
    BCardHeader,
  },
  props: {
    dataItem: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allCheck: '1',
    }
  },
  computed: {
    interviewCloseList() {
      const data = this.$store.getters['interviewCloses/dataItem']
      return data.interviews.data
    },
    interviewTransferList() {
      const data = this.$store.getters['interviewTransfer/dataItem']
      return data.interviews.data
    },
  },
  methods: {
    interviewCheck(value) {
      if (value === false) {
        this.allCheck = '0'
      }
    },
    interviewCloseCheckControl() {
      if (this.allCheck) {
        if (this.interviewCloseList.length > 0) {
          this.interviewCloseList.forEach(item => {
            if (item.selected) {
              item.selected = '1'
            }
          })
        } else if (this.interviewTransferList.length > 0) {
          this.interviewTransferList.forEach(item => {
            if (item.selected) {
              item.selected = '1'
            }
          })
        }
      } else if (this.interviewCloseList.length > 0) {
        this.interviewCloseList.forEach(item => {
          if (item.selected) {
            item.selected = '0'
          }
        })
      } else if (this.interviewTransferList.length > 0) {
        this.interviewTransferList.forEach(item => {
          if (item.selected) {
            item.selected = '0'
          }
        })
      }
    },
  },
}
</script>
