<template>
  <div>
    <b-card title="Müşteri Belirleme">
      <manager-search-phone-card />
      <b-row>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-checkbox
            v-model="filterData.customer"
            class="mt-2"
            name="check-button"
            value="1"
            switch
            inline
          >
            Müşteri Bilgileri Aktarımı
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-checkbox
            v-model="filterData.interviews"
            class="mt-2"
            name="check-button"
            value="1"
            switch
            inline
          >
            Görüşme Notları Aktarımı
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-checkbox
            v-model="filterData.sales"
            class="mt-2"
            value="1"
            name="check-button"
            switch
            inline
          >
            Sıfır Satış Dosyası Aktarımı
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-checkbox
            v-model="filterData.i2sales"
            class="mt-2"
            name="check-button"
            value="1"
            switch
            inline
          >
            2. El Satış Dosyası Aktarımı
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-checkbox
            v-model="filterData.services"
            class="mt-2"
            name="check-button"
            value="1"
            switch
            inline
          >
            Servis Randevu Kayıtları Aktarımı
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-checkbox
            v-model="filterData.cars"
            class="mt-2"
            name="check-button"
            value="1"
            switch
            inline
          >
            Araç Kayıtları Aktarımı
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-checkbox
            v-model="filterData.callforms"
            class="mt-2"
            name="check-button"
            value="1"
            switch
            inline
          >
            Biz size ulaşalım kayıtları aktarımı
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-checkbox
            v-model="filterData.testdrive"
            class="mt-2"
            name="check-button"
            value="1"
            switch
            inline
          >
            Test sürüşü kayıtları aktarımı
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-form-checkbox
            v-model="filterData.alerts"
            class="mt-2"
            name="check-button"
            value="1"
            switch
            inline
          >
            Görüşme hatırlatma kayıtları aktarımı
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-center">
            <b-button
              variant="primary"
              :disabled="!filterData.phone"
              @click="submitData"
            >
              Verileri Getir
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BButton, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import ManagerSearchPhoneCard from '@/views/ManagerTools/Elements/phone.vue'

export default {
  name: 'CustomerManagerSearchCard',
  components: {
    BFormCheckbox,
    ManagerSearchPhoneCard,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  props: {
    submitData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['customerDeduplication/searchFilter']
    },
  },
}
</script>
