<template>
  <div>
    <b-row>
      <b-col>
        <customer-manager-search-card :submit-data="getData" />
      </b-col>
    </b-row>
    <b-row v-if="dataLoading">
      <b-col>
        <b-alert
          show
          variant="info"
        >
          <div class="alert-body text-center">
            <loading />
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="dataItem.customers.length > 0">
      <b-col cols="12">
        <duplicated-customer-list />
      </b-col>
    </b-row>
    <b-row
      v-for="(item,index) in dataItem.data"
      :key="index"
    >
      <b-col cols="12">
        <result-list
          :table-name="item.tableName"
          :fields="item.fields"
          :data-item="item.data"
        />
      </b-col>
    </b-row>
    <b-row v-if="dataItem.data.length > 0">
      <b-col class="text-center">
        <b-button
          variant="primary"
          @click="deduplicateCustomer"
        >
          {{ submitBtnTitle }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BAlert, BButton,
} from 'bootstrap-vue'
import CustomerManagerSearchCard from '@/views/ManagerTools/Companents/SearchCard.vue'
import Loading from '@/layouts/components/Loading.vue'
import DuplicatedCustomerList from '@/views/ManagerTools/Companents/customerList.vue'
import ResultList from '@/views/ManagerTools/Companents/ResultList.vue'

export default {
  name: 'CustomerDeduplication',
  components: {
    ResultList,
    DuplicatedCustomerList,
    Loading,
    CustomerManagerSearchCard,
    BRow,
    BCol,
    BAlert,
    BButton,
  },
  data() {
    return {
      submitBtnTitle: 'Müşteriyi Seçili Müşteride Birleştir',
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    searchData() {
      return this.$store.getters['customerDeduplication/searchFilter']
    },
    dataLoading() {
      return this.$store.getters['customerDeduplication/dataLoading']
    },
    dataItem() {
      return this.$store.getters['customerDeduplication/dataItem']
    },
  },
  created() {
    // erişim kısıtlama işlemi
    if (this.userData.id !== '2' && this.userData.id !== '1') {
      this.$swal({
        icon: 'error',
        title: 'Uyarı',
        text: 'Bu alana giriş için yetkiniz bulunmuyor.',
        confirmButtonText: this.$store.state.app.removeResultClose,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
      this.$router.push('/')
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('customerDeduplication/getDataList', this.searchData)
    },
    deduplicateCustomer() {
      this.submitBtnTitle = 'İşlem Sürüyor..'
      this.$store.dispatch('customerDeduplication/deduplicateCustomer', {
        id_com_customer: this.searchData.id_com_customer,
        components: this.dataItem.components,
        customers: this.dataItem.customers,
      })
        .then(response => {
          if (response.status) {
            this.$swal({
              icon: 'success',
              title: 'Başarılı',
              text: 'Kayıtlar birleştirildi. Kayıtların yeni halini inceleyebilirsiniz.',
              confirmButtonText: this.$store.state.app.removeResultClose,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.getData()
          } else {
            this.$swal({
              icon: 'error',
              title: 'Uyarı',
              text: 'İşlem sırasında bir hata oluştu',
              confirmButtonText: this.$store.state.app.removeResultClose,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          this.submitBtnTitle = 'Müşteriyi Seçili Müşteride Birleştir'
        })
    },
  },
}
</script>
