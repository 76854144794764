<template>
  <b-card
    title="Bulunan Müşteriler"
    no-body
  >
    <b-card-header>
      <b-card-title>Bulunan Müşteriler</b-card-title>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      fixed
      small
    >
      <template #cell(control)="data">
        <b-form-radio
          v-model="dataList[data.index].transfer_status"
          name="check-button"
          value="1"
          inline
        />
      </template>
      <template #cell(kvkk)="data">
        {{ data.item.kvkk }}
        <div v-if="data.item.kvkkdate">
          <small class="text-primary">{{ data.item.kvkkdate }}</small>
        </div>
      </template>
      <template #cell(created)="data">
        {{ moment(data.item.created).format('DD.MM.YYYY') }}
      </template>
      <template #cell(name)="data">
        {{ data.item.name ? data.item.name : data.item.company_name }}
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BCard, BCardTitle, BCardHeader, BFormRadio,
} from 'bootstrap-vue'

export default {
  name: 'DuplicatedCustomerList',
  components: {
    BFormRadio,
    BTable,
    BCardTitle,
    BCard,
    BCardHeader,
  },
  data() {
    return {
      fields: [
        {
          key: 'control',
          label: '',
          thStyle: 'width:50px',

        },
        {
          key: 'id',
          label: 'ID',
          tdClass: 'font-small-3',
        },
        {
          key: 'name',
          label: 'Müşteri',
          tdClass: 'font-small-3',
        },
        {
          key: 'phone',
          label: 'Telefon',
          tdClass: 'font-small-3',
        },
        {
          key: 'kvkk',
          label: 'KVKK',
          tdClass: 'font-small-3',
        },
        {
          key: 'notes',
          label: 'Müşteri Notu',
          tdClass: 'font-small-3',
        },
        {
          key: 'username',
          label: 'Ekleyen',
          tdClass: 'font-small-3',
        },
        {
          key: 'created',
          label: 'Tarih',
          tdClass: 'font-small-3',
        },
      ],
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['customerDeduplication/dataItem']
      return data.customers
    },
    searchData() {
      return this.$store.getters['customerDeduplication/searchFilter']
    },
  },
}
</script>
