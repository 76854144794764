<template>
  <div>
    <b-form-group
      label="Tekleştirilecek Müşteri Telefon Numarası"
      label-for="phone"
    >
      <b-input-group>
        <b-input-group-prepend is-text>
          TR (+9)
        </b-input-group-prepend>
        <cleave
          id="phone"
          v-model="search.phone"
          class="form-control"
          :raw="false"
          :options="options.phone"
          placeholder="Telefon"
        />
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
  name: 'ManagerSearchPhoneCard',
  components: {
    BFormGroup,
    Cleave,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    search() {
      return this.$store.getters['customerDeduplication/searchFilter']
    },
  },
}
</script>
<style scoped></style>
